<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode, animated: false, fadeIn: true }">
    <!-- 장비정보 관리 --------------------------------------------------------------------------------------------->

    <BCard header-tag="header" footer-tag="footer">

      <div slot="header">
        <BIconHddNetworkFill/> <strong> 장비정보관리 </strong>
        <div class="card-header-actions">
          <small class="text-muted">장비정보를 관리합니다.</small>
        </div>
      </div>

      <BRow class="mb-2">
        <BCol>
          <BInputGroup size="sm">
            <BButton size="sm" variant="primary" class="mr-3" @click="createMachine">
              <BIconPatchPlusFill/>
              장비정보 생성
            </BButton>

            <BInputGroupAppend>
              <BFormSelect size="sm" v-model="searchField" :options="searchOpts" style="background-color:#2f303a"/>
              <BFormInput size="sm" v-model="searchWord" type="text" @keyup.enter="getMachineList"/>
            </BInputGroupAppend>

            <BButton size="sm" variant="info" @click="getMachineList">
              <BIconSearch/> 검 색
            </BButton>
          </BInputGroup>
        </BCol>

        <BCol sm="2" class="text-right">
          <BInputGroup size="sm">
            <BFormSelect size="sm" v-model="$store.state.numOfMachineItem" :options="[10,15,20,30,50,100]" style="background-color:#2f303a"/>
            <BInputGroupAppend>
              <BButtonGroup>
                <BButton size="sm" variant="primary" @click="getMachineList"><BIconArrowRepeat/></BButton>
                <BButton v-if=false size="sm" variant="info" @click="()=>{this.downloadModalShow = true}"><BIconFileSpreadsheetFill/></BButton>
              </BButtonGroup>
            </BInputGroupAppend>
          </BInputGroup>
        </BCol>
      </BRow>

      <BRow>
        <BCol>
          <vue-excel-editor
            v-model="rows"
            ref="excelGrid"
            width="100%"
            class="mb-1"
            :page="$store.state.numOfMachineItem"
            :readonly-style="{backgroundColor:'#4F5040'}"
            @update="updateCell"
            @select="selectRecord"
            :localized-label="excelEditorLabel"
            filter-row>
            <vue-excel-column field="_id"                     type="string"   key-field invisible/>
            <vue-excel-column field="mid"                     type="string"   width="80px" label='장비ID' sticky/>
            <vue-excel-column field="name"                    type="string"   width="140px" label='장비명' sticky/>
            <vue-excel-column field="tank"                    type="string"   width="160px" label='연결탱크'  :to-text="v=>{return v?`[${v.tid}] ${v.name}`:'' }" readonly sticky/>
            <vue-excel-column field="type"                    type="map"      width="100px" label='종류'  :options="maps.MACHINE_TYPE"/>
            <vue-excel-column field="modelName"               type="string"   width="100px" label='모델명'/>
            <vue-excel-column field="version"                 type="string"   width="100px" label='버전'/>
            <vue-excel-column field="serialNo"                type="string"   width="100px" label='시리얼'/>
            <vue-excel-column field="company"                 type="string"   width="100px" label='제조사'/>
            <vue-excel-column field="linkMid"                 type="map"      width="100px" label='연결장비' :options="machineMap"/>
            <vue-excel-column field="linkMchType"             type="map"      width="100px" label='연결장비종류' :options="maps.MACHINE_TYPE"/>
            <vue-excel-column field="linkType"                type="map"      width="100px" label='연결방식' :options="maps.DATALINK_TYPE"/>
            <vue-excel-column field="packetType"              type="map"      width="150px" label='패킷방식' :options="maps.PACKET_TYPE"/>
            <vue-excel-column field="deviceIp"                type="string"   width="130px" label='장비IP'/>
            <vue-excel-column field="sensorType"              type="map"      width="100px" label='센싱방식'  :options="maps.SENSOR_TYPE"/>
            <vue-excel-column field="sensorLen"               type="number"   width="100px" label='센서길이'/>
            <vue-excel-column field="pipeType"                type="map"      width="100px" label='파이프' :options="maps.PIPE_TYPE"/>
            <vue-excel-column field="joinLength"              type="number"   width="100px" label='체결길이'/>
            <vue-excel-column field="gainValue"               type="number"   width="100px" label='GAIN값'/>
            <vue-excel-column field="sensorWater"             type="number"   width="100px" label='수분플롯'/>
            <vue-excel-column field="oilRevBase"              type="number"   width="100px" label='오일보정치'/>
            <vue-excel-column field="wtrRevBase"              type="number"   width="100px" label='수분보정치'/>
            <vue-excel-column field="numOfTemp"               type="number"   width="100px" label='온도계수'/>
            <vue-excel-column field="numOfRev"                type="number"   width="100px" label='구간보정수'/>

<!--            <vue-excel-column field="thresholds"              type="string"   width="100px" label='경보임계치'  readonly/>-->
            <vue-excel-column field="serviceType"             type="map"      width="100px" label='그룹' :options="maps.SERVICE_TYPE"/>
            <vue-excel-column field="linkDate"                type="date"     width="110px" label='연결일시' :to-text="toLocalTime"/>
            <vue-excel-column field="linkStatus"              type="map"      width="100px" label='연결상태' :options="maps.LINK_STATUS"/>

            <vue-excel-column field="installDate"             type="date"     width="110px" label='설치일시' :to-text="toLocalTime"/>
<!--            <vue-excel-column field="warrantyStart"           type="date"     width="110px" label='보증시작' :to-text="toLocalTime"/>-->
<!--            <vue-excel-column field="warrantyEnd"             type="date"     width="110px" label='보증종료' :to-text="toLocalTime"/>-->
            <vue-excel-column field="comment"                 type="string"   width="150px" label='비고' />

            <vue-excel-column field="updatedAt"               type="date"     width="110px" label='수정일' :to-text="toLocalTime" readonly/>
            <vue-excel-column field="updId"                   type="string"   width="100px" label='수정자' readonly/>
            <vue-excel-column field="createdAt"               type="date"     width="110px" label='등록일' :to-text="toLocalTime" readonly/>
            <vue-excel-column field="regId"                   type="string"   width="100px" label='등록자' readonly/>

          </vue-excel-editor>
        </BCol>
      </BRow>

      <BRow>
        <BCol>
          <BButtonGroup>
            <BButton variant="info"  @click="showDetail" :disabled="selectedMachine===null">
              <BIconInfoSquareFill/> 상세 보기
            </BButton>
            <BButton variant="danger" class="ml-2" @click="deleteRecord" :disabled="selectedMachine===null">
              <BIconTrashFill/> 선택 정보 삭제
            </BButton>
          </BButtonGroup>
        </BCol>
        <BCol>
          <BProgress height="2rem" :value="progCount" :max="totalCount" variant="warning" show-value/>
        </BCol>
      </BRow>
    </BCard>



    <BModal id="modal-detail"
            ref="modalDetail"
            size="lg"
            title="장비 상세정보"
            hide-header
            @ok="getMachineList"
            scrollable no-close-on-backdrop>
      <BRow>
        <BCol>
          <BCard v-if="selectedMachine!==null" class="p-1 min-vh-100" no-body>
            <BButton block variant="dark" class="mb-2" disabled>장비 정보</BButton>
            <machine-form
              :machine-id.sync="selectedMachine._id"
              :only-machine="true"
              :tank-id="selectedTankId">
            </machine-form>
          </BCard>
        </BCol>
      </BRow>
    </BModal>



    <CModal ref="download-modal"
            color="warning"
            title="다운로드 사유 입력"
            :show.sync="downloadModalShow">
      <BFormInput v-model="downloadReason" debounce="6000"></BFormInput>
      <b-button class="mt-3" variant="outline-danger" block @click="exportExcel"><BIconDownload/> 다운로드</b-button>
    </CModal>

  </div>
</template>

<style src="spinkit/spinkit.min.css"></style>

<script>

//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  apiCall,
  cloneVar,
  accessLogging, getRandomHex,
} from '@/common/utils';
import qs from 'querystring';
import moment from "moment";
import {ExcelEditorLabel} from '@/common/constants'
import MachineForm from '../components/MachineForm'
import {MachineSchema, MachineColumnMap} from "@/common/schema";


// const recvTypeMap = { manual: "매뉴얼", api: "API", socket: "소켓" };


//----------------------------------------------------------------------------------------------------
export default {
  name: "Machine",
  components: {
    MachineForm
  },
  data () {
    return {
      maps: this.$store.state.codeMaps,
      selectedMachine: null,
      selectedTankId: null,
      tank : null,
      machineMap: {},
      downloadModalShow: false,
      oilCodeMap  : {},
      arCodeMap  : {},
      arCodeOpts  : [],
      downloadReason: '',
      searchOpts: [
        {value:'mid',        text:'장비아이디'},
        {value:'name',       text:'장비명'},
        {value:'modelName',  text:'모델명'},
      ],
      searchField: 'mid',
      searchWord : '',
      progCount: 0,
      totalCount: 0,
      rows: [],

      paramMap: {},
      numOfRecord: 10,

      modalTitle: '',
      tankFormShow: false,

    }

  },
  async created(){
    try{
      await this.setMachineMap();

      await this.getMachineList();

    }catch(err){
      console.log(err);
    }
  },

  computed: {
    excelEditorLabel() {
      return ExcelEditorLabel
    }
  },

  mounted() {
    console.log("--- MachineForm mounted---------------------");
    // below is not work!
  },

  methods: {

    async createMachine(){
      let newMachine;
      try{

        if( !await this.confirmModal(`신규 장비정보를 등록합니다. 진행 하시겠습니까?`, '장비정보 등록') ){
          await this.alertModal( '장비정보 등록 취소', '장비정보');
          return;
        }else{
          newMachine = cloneVar(MachineSchema);
        }

        if(this.selectedMachine!==null){
          if( await this.confirmModal(`장비아이디 '${this.selectedMachine.mid}' 정보를 복제하여 등록합니다. 진행하시겠습니까?`, '장비정보 복제') ){
            newMachine = cloneVar(this.selectedMachine); // 복제등록 _id 제거, 제거안하면 duplication error
            delete newMachine._id;
            newMachine.name = newMachine.name+'-copy';
            newMachine.updId = '';
            newMachine.regId = '';
          }
        }

        newMachine.mid = getRandomHex(4);
        newMachine.tank = null;

        const r = await apiCall('post', `/api/machine`, newMachine);

        if( r.code===200){
          await this.alertModal( '장비정보가 등록되었습니다', '장비정보 등록', 'success');
          await this.getMachineList();
        }else{
          await this.alertModal('장비정보 등록 실패: '+r.message,'장비정보 등록 실패', 'warning');
        }

      }catch(err){
        console.log( err );
        await this.alertModal('장비정보 등록 실패:'+err.message,'장비정보 등록 오류', 'danger');
      }

    },

    async getMachineList(){
      try{
        let qry = '';
        this.paramMap = {};
        if(this.searchWord) this.paramMap[this.searchField] = this.searchWord;
        qry = qs.stringify( this.paramMap );
        console.log( "query-string ---------- ", qry );


        this.rows = [];
        const r = await apiCall('get', `/api/machine?${qry}`);

        if(r.code===200){
          this.rows = r.result;
          await this.toastInfo(`${this.rows.length}건 조회됨`, 'info');
          // console.log( 'task-data-query-result--->', r.result  );
        }

        if(this.$refs['excelGrid']) this.$refs['excelGrid'].clearFilter();

        this.selectedMachine = null;
      }catch(err){
        await this.alertDanger(err.message);
        console.log( err );
      }

    },

    async setMachineMap(){
      try{
        this.machineMap = {};
        const r = await apiCall('get', `/api/machine`);
        for( let m of r.result ){
          this.machineMap[m.mid] = `${m.name}-${m.name}`;
        }
      }catch(err){
        console.log( err );
      }

    },

    async exportExcel(){
      try{
        if( !this.downloadReason || this.downloadReason.length < 5 ){
          return await this.alertWarn( "다운로드 사유는 5자 이상 입력해야 합니다.", '사유 없음');
        }

        const numRecs = this.$refs['excelGrid'].getSelectedRecords().length;

        if(!numRecs){
          this.$refs['download-modal'].hide();
          return await this.alertWarn("선택한 레코드만 파일로 생성됩니다.",'선택 레코드 없음');
        }
        const {currentRoute} = this.$router;
        const {fields} = this.$refs["excelGrid"];
        const format = 'xlsx';
        const exportSelectedOnly = true;

        const filename = currentRoute.path.split('/').pop()+'-'+moment().format('YYYY-MM-DD');
        const labels = fields.map(i=>{ return i.label })
        const rs = await accessLogging(
          'download',
          currentRoute,
          labels,
          this.downloadReason,
          `레코드수: ${numRecs}, 파일명: ${filename}.${format}`
        );
        if(rs===true){
          this.$refs["excelGrid"].exportTable(format, exportSelectedOnly, filename);
          this.downloadReason = '';
          return true;
        }else{
          await this.alertDanger("ERROR: "+rs.message, rs.code);
          return false;
        }

      }catch(err){
        console.log( 'exportExcel error', err);
      }finally{
        this.$refs['download-modal'].hide();
      }
    },

    async selectRecord(idx, evt){
      if(!evt){
        // this.tankFormShow = false;
        this.selectedMachine = null;
        this.selectedTankId = null;
        return;
      }

      this.selectedMachine = this.$refs['excelGrid'].getSelectedRecords().pop();
      this.selectedTankId = (this.selectedMachine.tank)?this.selectedMachine.tank._id: null;

      console.log( 'select idx, tank --->', idx, this.selectedMachine );

    },
    async showDetail(){
      try{
        // console.log("arr -----------------> ", arr);
        if( !this.selectedMachine ){
          await this.toastInfo( `레코드를 선택 하세요`, 'warning');
          return;
        }
        this.$refs['modalDetail'].show();

      }catch(err){
        console.log("showDetail error:", err);
      }
    },


    validateCell(val, oldVal, rec, field){
      console.log("----------------validateCell-------------------");
      console.log( "validateCell---value ---> ", val );
      console.log( "validateCell---oldVal ---> ", oldVal );
      console.log( "validateCell---rec ---> ", rec );
      console.log( "validateCell---field ---> ", field );
      // console.log( "validateCell---",this.$refs['excelGrid'] );
      // console.log( "validateCell---",this.$refs['excelGrid'].selectRecord(0) );
    },


    async updateCell(rec){

      let rc = rec.pop();
      if(!rc.keys[0]){
        alert( "KEY_NOT_FOUND" );
        return;
      }

      console.log( "updateRecord--------- record.$id--->",rc.$id );
      console.log( "updateRecord--------- record.keys[0]--->",rc.keys[0] );
      // console.log( "updateRecord--------- rec[0]|rc --->",rc );
      // const tIndex = this.$refs['excelGrid'].rowIndex[rc.$id];
      // console.log( "updateRecord--------- rowIndex--->",tIndex );
      // let row = this.$refs['excelGrid'].table[tIndex];
      //
      // console.log( "row ---------> ", row );

      let r = null;

      let param = {};
      const objectId = rc.keys[0];
      const fieldName = rc.name;
      param[fieldName] = rc.newVal;
      try{
        // const row = this.$refs['excelGrid'].currentRecord;
        console.log( 'param --->', param);
        r = await apiCall("PUT", `/api/machine/${objectId}`, param);
        console.log( r );
        await this.toastResult(r, `${MachineColumnMap[fieldName]} 수정`);
      }catch(err){
        await this.alertDanger(err.message, r.code, 'danger');
        console.log( err );
      }

    },

    async deleteRecord(recs){
      // let r = null, msg = null, notice = null;
      try{

        let params = this.$refs['excelGrid'].getSelectedRecords();
        this.progCount = 0;
        this.totalCount = params.length;
        if(!params.length) return this.toastInfo('[선텍된 레코드 없음] 좌측의 번호를 클릭하여 레코드 선택');

        console.log( "deleteRecord --- recs ---", recs.length );
        console.log( "deleteRecord --- params ", params );
        console.log( "deleteRecord length ----> ", params.length );

        const confirmMsg = `${params.length} 개의 데이터를 삭제 합니다. 삭제된 데이터는 복구할 수 없으며 시스템 장애가 발생할 수 있습니다. 진행 하시겠습니까?`;

        if( !(await this.confirmModal(confirmMsg, '레코드 삭제')) ){
          return;
        }

        let failCnt = 0;
        for( let record of params){
          console.log( "deleteRecord for --------->", record );
          const tIndex = this.$refs['excelGrid'].rowIndex[record.$id];
          const r = await apiCall('DEL', `/api/machine/${record._id}`);
          console.log( r );
          if( r.code===200 ){
            this.progCount++;
            this.$refs["excelGrid"].deleteRecord(tIndex);
          }else{
            failCnt++;
          }
        }

        await this.alertModal(`삭제: ${this.progCount}건, 실패: ${failCnt}`, '레코드 삭제결과', 'warning');
        this.$refs['excelGrid'].clearAllSelected();
        await this.getMachineList();

      }catch(err){

        console.log( err );
      }
    },


  }
}
</script>
<style>
.v-scroll-button {
  background-color: #888 !important;
  display: block !important;
  width: 12px;
  border-radius: 6px;
}

.v-scroll-button .runner {
  display: block !important;
}

.vue-excel-editor .table-content .systable .center-text .table-col-header {
  pointer-events: none;
}
</style>
